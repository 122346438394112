/**
 * These functions exist to be backwards-compatible with the date-fns-tz public
 * interface. If these don't do what you want as-is, prefer using the
 * `@date-fns/tz` and `@date-fns/utc` packages directly.
 */

import { tz } from "@date-fns/tz";
import { UTCDate } from "@date-fns/utc";
import { isValid, toDate, transpose } from "date-fns";

export function utcToZonedTime(date: Date | string, timeZone: string): Date {
  timeZone = timeZone === "Z" ? "UTC" : timeZone;
  return transpose(toDate(date, tz(timeZone)), toDate);
}

export function zonedTimeToUtc(date: Date | string, timeZone: string): Date {
  timeZone = timeZone === "Z" ? "UTC" : timeZone;

  if (!isValidTimeZone(timeZone)) {
    return new Date(NaN);
  }

  return new UTCDate(transpose(toDate(date), tz(timeZone)));
}

function isValidTimeZone(timeZone: string) {
  return isValid(toDate(0, tz(timeZone)));
}
